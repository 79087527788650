<template>
  <div
      :class="isMobile() ? 'report-search report-search-mobile' : 'report-search'"
  >
    <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
    ></loading>

    <div v-if="!isMobile()">
      <CRow md="12">
        <!--	<CRow :style="{height: window.height-230 + 'px',width:'100%'}">-->
        <CCol md="9" style="padding: 0px">
          <OlMap
              ref="olMap"
              :style="{ height: window.height - 150 + 'px' }"
              @genreport="submitreport"
          />
        </CCol>
        <CCol md="3" style="padding: 0px">
          <MapMenu
              ref="mapMenu"
              @changebasemap="changebasemap"
              @genreport="genreport"
              @reset="reset"
              @samplereport="samplereport"
              @searchaddress="searchaddress"
          />
        </CCol>
      </CRow>
    </div>
    <div v-else style="position: fixed; right: 0px; left: 0px">
      <CRow>
        <CCol>
          <OlMap
              ref="olMap"
              :style="{ height: window.height - 120 + 'px' }"
              @genreport="submitreport"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <MapMenu
              ref="mapMenu"
              @changebasemap="changebasemap"
              @genreport="genreport"
              @reset="reset"
              @samplereport="samplereport"
              @searchaddress="searchaddress"
          />
        </CCol>
      </CRow>
    </div>

    <CModal :show.sync="showLocationErrorModal" color="danger" title="Error">
      <h5 class="text-center">
        <CIcon
            customClasses="custom-icon-dange-w50p"
            name="cil-x-circle"
        ></CIcon
        >
        <br/>請在地圖上選一個位置
      </h5>
      <template #footer>
        <CButton color="danger" @click="showLocationErrorModal = false">關閉</CButton>
      </template>
    </CModal>

    <CModal :show.sync="showDataErrorModal" color="danger" title="Error">
      <h5 class="text-center">
        <CIcon
            customClasses="custom-icon-dange-w50p"
            name="cil-x-circle"
        ></CIcon
        >
        <br/>請選擇產業
      </h5>
      <template #footer>
        <CButton color="danger" @click="showDataErrorModal = false">關閉</CButton>
      </template>
    </CModal>

    <CModal
        ref="warningModal"
        :show.sync="showWarningModal"
        color="warning"
        title="提醒"
    >
      <h5>
        請確認<strong>"選取位置"</strong>是否正確，一旦按下<strong>[確認]</strong>送出後，<strong
      >將占用您1個報表授權數量</strong
      >
      </h5>
      <template #footer>
        <CButton color="warning" @click="showWarningModal = false"
        >取消
        </CButton
        >
        <CButton color="danger" @click="confirmSubmitReport">確認</CButton>
      </template>
    </CModal>

    <CModal
        ref="warningModal"
        :show.sync="showSampleModal"
        color="warning"
        title="Warning"
    >
      <h5 class="text-center">
        <CIcon
            customClasses="custom-icon-warning-w50p"
            name="cil-x-circle"
        ></CIcon
        >
        <br/>此為使用台北火車站之範例報告，如需其他位置報告，請至交易紀錄購買
      </h5>
      <template #footer>
        <CButton color="warning" @click="showSampleModal = false">關閉</CButton>
        <CButton color="danger" @click="confirmSubmitSampleReport"
        >確認
        </CButton
        >
      </template>
    </CModal>

    <CModal
        :show.sync="showSampleReportErrorModal"
        color="danger"
        title="Error"
    >
      <h5 class="text-center">
        <CIcon
            customClasses="custom-icon-dange-w50p"
            name="cil-x-circle"
        ></CIcon
        >
        <br/>無範例報告
      </h5>
      <template #footer>
        <CButton color="danger" @click="showSampleReportErrorModal = false">關閉</CButton>
      </template>
    </CModal>

    <ABTest
        :key="abTestComponentKey"
        ref="refABTest"
        :shouldMount="true"
        @saveFreeTrailData="$refs.mapMenu.getTicketList()"
    ></ABTest>
  </div>
</template>


<script>
import "vuejs-dialog/dist/vuejs-dialog.min.css";

import Loading from "vue-loading-overlay";
import axios from "axios";
import OlMap from "../../components/ol.vue";
import MapMenu from "../../components/olMenu.vue";
import appSettings from "../../util/settings";
import store from "../../util/store";
import mobile from "../../util/mobile";

import ABTest from "../abTest/ABTest";

let abTestComponentIndex = 1;

export default {
  name: "MarketReport",
  components: {
    OlMap,
    MapMenu,
    Loading,
    ABTest,
  },
  data() {
    return {
      industry: undefined,
      radius: undefined,
      price: undefined,

      showLocationErrorModal: false,
      showWarningModal: false,
      showSampleModal: false,

      showSampleReportErrorModal: false,
      showDataErrorModal:false,
      x: undefined,
      y: undefined,

      useItemType: undefined,
      useItemCode: undefined,
      isLoading: false,

      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },

      abTestComponentKey: abTestComponentIndex++,
    };
  },
  mounted() {
    let validToken = this.validToken;
    axios
        .post(appSettings.ValidToken, {
          token: store.state.token,
        })
        .then(validToken)
        .catch(function (error) {
          console.log(error);
        });
  },
  methods: {
    changebasemap(style) {
      this.$refs.olMap.changebasemap(style);
    },
    confirmSubmitReport() {
      let x = this.x;
      let y = this.y;
      let industry = this.industry;

      this.$cookies.set("centerX", x);
      this.$cookies.set("centerY", y);
      this.$cookies.set("industry", industry);

      //console.log(this.$cookies.get("industry"));

      //console.dir("submit");

      this.$router.push({
        path: "/marketReportResult",
        name: "MarketReportResult",
        params: {
          x,
          y,
          industry,
          radius: this.radius,
          price: this.price,
          useItemType: this.useItemType,
          useItemCode: this.useItemCode,
        },
      });
    },
    confirmSubmitSampleReport() {
      this.showSampleModal = false;
      //this.isLoading = true;
      this.$router.push({
        path: "/marketReportResult",
        name: "MarketReportResult",
        params: {
          action: "showSampleReport",
        },
      });
    },
    genreport(industry, radius, price, useItemType, useItemCode) {
      if(industry == null)
      {
          this.showDataErrorModal =true;
          return;
      }
   
        this.industry = industry;
        this.radius = radius;
        this.price = price;
        this.useItemType = useItemType;
        this.useItemCode = useItemCode;
        this.$refs.olMap.genreport(radius);
      
    }, 
    isMobile: function () {
      return mobile.isMobile();
    },
    reset() {
      this.$refs.olMap.reset();
    },
    samplereport() {
      this.showSampleModal = true;
    },
    searchaddress(data) {
      this.$refs.olMap.searchaddress(data);
    },
    submitreport(x, y) {
      if (x === undefined || y === undefined) {
        this.showLocationErrorModal = true;

      } else {
        this.showWarningModal = true;
        this.x = x;
        this.y = y;

      }
    },
    validToken(response) {
      var data = response.data;
      //console.log(data);
      if (!data.IsLogin) {
        store.commit("clearUser");
        this.$router.replace({name: "Login"});
      }

      //console.log('width:'+ screen.width);
      //console.log('height:'+ screen.height);
    },
  },
};
</script>
