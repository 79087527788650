<template>
    <div>
        <CButton @click="showNoticeModal" class="btn-Bell-type map-notice" color="light" v-if="showNotice">
            <CIcon name="cil-bell" customClasses="custom-icon-w85" />
            <small class="badge badge-pill badge-danger">!</small>
        </CButton>
    </div>
</template>

<script>

    export default {
        name: 'Notice',
        components: { 
        },
        props: { 
        },
        data() {
            return {
                showNotice: false
            }
        },
        mounted() { 
        },
        methods: {
            showNoticeModal() {
                let ABTestComponent = this.$parent.$parent.$refs["refABTest"];
                if (ABTestComponent !== undefined) {
                    let data = ABTestComponent.audienceInfo;
                    if (data.LED !== null) {
                        ABTestComponent.showFreeTrailModal = true;
                    }
                    else if (data.Type == "B") {
                        ABTestComponent.showSurveyToFreeTrailModal = true;
                    }
                } 
            }
        }
    }
</script>
