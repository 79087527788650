<template>
  <div style="height: 100%;"> 
    <!--地圖上的額外button-->  
	<CInput class="map-searchaddress"  id="address-input" ref="content" v-model="address" placeholder="輸入地址查詢或點擊地圖">
	<!--<template #prepend>
		<CButton  style="background-color: #fff;" @click='searchAddress'> <CIcon name="cil-location-pin" /></CButton>
	</template>
	-->
	<template #append> 
		<CButton  color="success" @click='searchAddress'><svg-img-icons  name="magnifying-glass" width="20" height="20" /><!--<CIcon  name="cil-magnifying-glass" customClasses="custom-icon-w20p" title="搜尋" />--> </CButton> 
		<CButton  color="success2" @click='locateToCurrentPosition'><svg-img-icons name="gps" width="22" height="22" /> <!--<CIcon name="cil-location-pin" customClasses="custom-icon-w20p" title="您的位置" />--></CButton>
	</template> 
	</CInput>   
	
	<CButton  @click="collapse = !collapse" class="btn-map-type"  color="light"  ><CIcon name="cil-layers" customClasses="custom-icon-w85" /></CButton>   
	<CCollapse :show="collapse">
			<div class="btn-map-type-div" >
			<p><small>地圖類型</small></p>
			<CRow class="basemap text-center">
					<CCol><a href="#" onclick="" data-toggle="tooltip" title="Hooray!">
						<img id="GMHImage" src="../../public/img/maps/img_googleroadmap.png" @click='changeGoogleMapRoad'><p>谷歌</p>
						</a></CCol>
					<CCol><a href="#" onclick="">
						<img id="GMDImage" src="../../public/img/maps/img_googleroadmap_dark.png" @click='changeGoogleMapDark'><p>谷歌(深色)</p>
						</a></CCol>
			</CRow> 
		</div>
	</CCollapse> 
	<Notice ref="refNotice"/> 
	<div id="map" style="height: 100%; width:100%;"></div>
   <div class="adjust-modal">
	<CModal
        title="Error"
        color="danger"
        :show.sync="showErrorModal">
        <h5 class="text-center"><CIcon name="cil-x-circle" customClasses="custom-icon-dange-w50p"></CIcon><br>{{errorMessage}}</h5>
        <template #footer>
            <CButton @click="closeMessageDialog" color="danger">關閉</CButton>
        </template>
	</CModal>

    <CModal
        title="提醒"
        color="warning"
        :show.sync="showWarningModal">
        <h5 class="text-center"><CIcon name="cil-location-pin" customClasses="custom-icon-dange-w50p"></CIcon><br>系統要求您的定位權限</h5>
        <template #footer>
            <CButton @click="showWarningModal=false" color="warning"><span class="text-white">關閉</span></CButton>
        </template>
	</CModal>

   </div>
  </div>
</template>

<script>
import axios from 'axios';
import {Circle, Point} from 'ol/geom';
import mapfunction from '../util/mapfunction'
import appSettings from '../util/settings';
import store from '../util/store.js';
import Notice from '../views/abTest/Notice'; 
 

export default {
	name: 'olMap',
	components: {  
        Notice
    },
	props: {

	},
	methods:{
		reset(){
			this.map.ClearMeasure();
			this.measureFunction();
			this.map.removeCircle("cc");
			this.map.removePoint("pp");

			this.$cookies.remove("centerX");        
			this.$cookies.remove("centerY");
			
			this.x = undefined;
			this.y = undefined;
		},
		drawEndCallBack(geomObjects){
			//console.log(geomObjects);
			this.map.removePoint("pp");
			this.map.StopMeasure();			
			if (geomObjects instanceof Point) {
				var pointCoord = geomObjects.getCoordinates();
				//console.log(this);
				//console.log(pointCoord[0]);
				this.x = pointCoord[0];
				this.y = pointCoord[1];
			}
			else if (geomObjects instanceof Circle) {
				var center = geomObjects.getCenter();
				var radius = geomObjects.getRadius();
				//console.log(center);
				//console.log(radius);
			}
		},
		measureFunction(){			
			this.map.StartMeasure("Point", false, function (){}, this.drawEndCallBack);
		},
		searchaddress(data){
			this.reset();
			this.map.createPointByLonLat("pp", data.x, data.y, "#ff143f");
			this.map.centerByLonLat(data.x, data.y - 0.0005);
			this.map.setZoom(18);
			var pointCoord = this.map.convert4326To3857(data.x, data.y);
			this.x = pointCoord[0];
			this.y = pointCoord[1];
		},
		genreport(radius){
			//console.log(this.x);
			//console.log(this.y);
			this.$cookies.set("zoom", this.map.getZoom());
			this.map.removeCircle("cc");
			this.map.createCircle("cc", radius, this.x, this.y);
			this.$emit("genreport", this.x, this.y);
		},
		changebasemap(style){
			 this.map.setBaseMap(style, false, "OverviewMap");
		},
		searchAddressResult(response){
			var data = response.data;

			if(!data.IsLogin){
				store.commit("clearUser");
				this.$router.replace({ name: "Login" });
			}
			else if(data.Status === 0){
				this.searchaddress(data);
			}
			else{
				this.errorMessage = data.Message;
				this.showErrorModal = true;
			}
		},
		searchAddress(){
			var searchAddress = this.address;

			axios.post(appSettings.SearchAddress, 
						{
							searchAddress,
							idToken:store.state.token
						})
						.then(this.searchAddressResult)
						.catch(function (error) { 
							console.log(error);
						});
		}, 
		closeMessageDialog(){
                this.showErrorModal = false;  
        },
		changeGoogleMapRoad(){
			this.changeBaseMap(mapfunction.MapStyle.GoogleMapRoad);
		},
		changeGoogleMapDark(){
			this.changeBaseMap(mapfunction.MapStyle.GoogleMapDark);
		}, 
		chagneGaoDe(){
			this.changeBaseMap(mapfunction.MapStyle.GaoDe);
		},
		changeGaoDeHyBrid(){
			this.changeBaseMap(mapfunction.MapStyle.GaoDeHybrid);
		},
		changeBaseMap(mapStyle){
			this.changebasemap(mapStyle);
		},
		locateToCurrentPosition(){   
			var self = this;
			this.getCurrentPosition().then(function(){ 
				if(self.currentPosition!==undefined){ 
					var data = {x:self.currentPosition.longitude,y:self.currentPosition.latitude};
					//this.searchaddress(data); 
					self.reset();
					self.map.createPointByLonLat("pp", data.x, data.y, "#ff143f");
					self.map.centerByLonLat(data.x, data.y);
					self.map.setZoom(18);
					var pointCoord = self.map.convert4326To3857(data.x, data.y);
					self.x = pointCoord[0];
					self.y = pointCoord[1];
					//console.log('google_long=>:' +data.x);
					//console.log('google_lat:' +data.y);
					//console.log('3857_x=>'+self.x);
					//console.log('3857_y=>'+self.y);
					//self.map.animatePointByLonLat(data.x, data.y);
				}  
			}).catch(function (error) { 
			   self.showWarningModal=true;  
            }); 
		},
		getCurrentPosition(){
		     return new Promise((resolve,reject) => {  
					if(navigator.geolocation) {
						var self = this;
						navigator.geolocation.getCurrentPosition(
						function(position){ 
							self.currentPosition = position.coords; 
							resolve(); 
						},
						function (error) {
							reject();
						})
					}  
			 })
		},				
		checkKeyDown() {
			if(event.key === 'Enter') {
				//alert('Enter');        
				this.searchAddress();
			}
		},	
		getFocus(){
			this.$refs.content.$el.children[0].children[0].onkeydown = this.checkKeyDown;
			this.$refs.content.$el.children[0].children[0].focus();
			//console.log(this.$refs.content.$el.children[0].children);
		}, 
	},
	data(){
		return {
			map:undefined,
			x:undefined,
			y:undefined,

			currentPosition:undefined, 
			address: '', 
            //collapse
			collapse: false,
			innerCollapse: false,
			showErrorModal:false,  
			errorMessage:"",
			showWarningModal: false, 
		}
	},
		mounted() { 
		var bingMapsKey = "";
		var mapBoxKey = "";

		var mapFactory = mapfunction.createMapFactory(bingMapsKey, mapBoxKey);

		var x = this.$cookies.get("centerX");		
		
		if(!x){
			x = 0;
		}
		
		var y = this.$cookies.get("centerY");
		if(!y){
			y = 0;
		}

		var map = mapFactory.createMap('map',
			"",
			{
				zoomTipLabel: { zoomInTipLabel: 'ZoomIn', zoomOutTipLabel: 'ZoomOut' },
				zoomValue: {
					default: 12,
					minZoom: 1,
					maxZoom: 20
				},
				center: [x, y]
			});

		if(x == 0 && y == 0){
			x = 13528857;
            y = 2883682;
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					function (position) {
						//console.log(position.coords.longitude);
						//console.log(position.coords.latitude);
						map.centerByLonLat(position.coords.longitude, position.coords.latitude);
					},
					function (error) {
						//alert(error.message);
						map.centerFromWM(x, y);
					}, {
						enableHighAccuracy: true
						, timeout: 1000
					}
				);
			} else {
				map.centerFromWM(x, y);
				//alert("Geolocation is not supported by this browser.");				
			}
		}		
			
			
		this.map = map;		
		
		map.setBaseMap(mapfunction.MapStyle.GoogleMapRoad, false, "OverviewMap");
		//map.createFullScreen('FullScreen');
		map.createScaleLine();
		//map.createZoomSlider();
		//map.createZoomToExtend("Fix To Extend");

		var zoom = this.$cookies.get("zoom");
		if(zoom){
			map.setZoom(zoom);
		}

		this.measureFunction(); 

		this.getFocus();
	}
}
</script>

<style>
.map:-moz-full-screen {
	height: 100%;
	max-width: 100%;
	flex: none;
}

.map:-webkit-full-screen {
    height: 100%;
    max-width: 100%;
    flex: none;
}

.map:-ms-fullscreen {
    height: 100%;
    max-width: 100%;
    flex: none;
}

.map:fullscreen {
    height: 100%;
    max-width: 100%;
    flex: none;
}

.ol-rotate {
    top: 3em;
}

.ol-scale-line {
    bottom: .5em;
    right: 8px;
    left: auto;
}
</style>	
