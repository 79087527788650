<template>
  <div>
    <!--<CButton @click="showFreeTrailModal = true" class="btn-Bell-type map-notice" color="light" v-if="showNotice">
            <CIcon name="cil-bell" customClasses="custom-icon-w85" />
            <small class="badge badge-pill badge-danger">!</small>
        </CButton>-->

    <CModal
      :centered="true"
      class="lessModal"
      ref="refFreeTryModal"
      :show.sync="showFreeTrailModal"
    >
      <div class="h5 text-center">
        <img
          height="70"
          :src="'/img/ABTest/animation bell gif.gif'"
        /><br /><br />
        <h3 class="text-green"><strong>溫馨提醒</strong></h3>
        <br />
        <p>
          您有1次免費試用,到期日為<strong class="text-green">{{
            audienceInfo.LED
          }}</strong>
        </p>
        <p>請儘早使用,逾期將不補發</p>
      </div>
      <template #header>
        <div></div>
      </template>
      <template #footer>
        <CButton @click="close" color="success3" class="btn-block"
          ><span class="text-white">了解, 謝謝</span></CButton
        >
        <br />
      </template>
    </CModal>
    <CModal
      :centered="true"
      class="lessModal"
      :show.sync="showSurveyToFreeTrailModal"
    >
      <div class="h5 text-center">
        <svg-img-icons name="questionnaire-icon" width="260" height="260" />
        <h3 class="text-green"><strong>填問卷送好禮</strong></h3>
        <br />
        <p>凡是新註冊會員完成問卷填寫</p>
        <p>即可獲得免費商圈評估報告乙份</p>
        <p>(每組帳號限填乙次)</p>
      </div>
      <template #header>
        <div></div>
      </template>
      <template #footer>
        <CButton
          @click="doSurveyModalNotShowAgain"
          color="dark"
          class=""
          v-if="showSurveyNotShowAgainBtn"
          ><span class="text-white">不再提醒</span></CButton
        >
        <CButton @click="doSurveyToFreeTrail" color="success3" class=""
          ><span class="text-white">開始問卷</span></CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import appSettings from "../../util/settings";
import store from "../../util/store";

let storeKey = store.state.userName + "_isNoticeSurvey";

export default {
  name: "ABTest",
  props: {
    shouldMount: Boolean,
  },
  components: {},
  data() {
    return {
      showFreeTrailModal: false,
      showSurveyToFreeTrailModal: false,
      showSurveyNotShowAgainBtn: true,
      audienceInfo: {
        UserId: null,
        Type: null,
        IsUsed: null,
        IsNotice: null,
        Enable: null,
        LSD: null,
        LED: null,
      },
      closeCallbackFunction: undefined,
    };
  },
  mounted() {
    if (this.shouldMount) {
      this.setABTest();
    }
  },
  methods: {
    close() {
      this.showFreeTrailModal = false;
      if (this.closeCallbackFunction !== undefined) {
        this.closeCallbackFunction();
      }
    },
    setABTest() {
      let self = this;
      axios
        .post(appSettings.GetAudience, { token: store.state.token })
        .then(function (response) {
          let data = response.data.Result;
          if (data != null) {
            self.audienceInfo = data;
          }

          if (data.Type == "A") {
            //原本流程-Nothing to do
            return;
          } else if (data.Type == "B" && data.LED == null) {
            //填問卷流程
            let isNoticeSurvey = store.state[storeKey];
            //console.log(isNoticeSurvey)
            if (isNoticeSurvey == undefined) {
              self.showSurveyToFreeTrailModal = true;
            } else if (isNoticeSurvey == true) {
              self.showSurveyNotShowAgainBtn = false;
            }
            self.showFreeTrailModal = false;
          } else if (data.Type == "C" && data.LED == null) {
            //直接免費
            self.doFreeTrail();
            self.showSurveyToFreeTrailModal = false;
          } 

          if (data.IsUsed === false && data.Enable !== 0 ) {
            self.showNoticeOnMap();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    doFreeTrail() {
      //set lsd led
      let self = this;
      axios
        .post(appSettings.UpdateAudience, {
          token: store.state.token,
          isNotice: true,
        })
        .then(function (response) {
          let data = response.data;
          if (data.IsSuccess == true) {
            if (data.Result != null) {
              self.audienceInfo = data.Result;
            }

            self.showFreeTrailModal = true;
            //self.showNoticeOnMap();

            self.$emit("saveFreeTrailData");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    doSurveyToFreeTrail() {
      this.showSurveyToFreeTrailModal = false;
      //轉到問卷頁
      this.$router.push({
        name: "Survey",
      });
    },
    doSurveyModalNotShowAgain() {
      let isNoticeSurvey = store.state[storeKey];
      if (isNoticeSurvey == undefined) {
        store.commit("set", [storeKey, true]);
      }
      this.showSurveyToFreeTrailModal = false;
    },
    showNoticeOnMap() {
      if (this.$parent.$refs["olMap"] != undefined) {
        let refNoticeC = this.$parent.$refs["olMap"].$refs["refNotice"];
        //console.log(this.$parent.$refs["olMap"].$refs["refNotice"]);
        if (refNoticeC !== undefined) refNoticeC.showNotice = true;
      }
    },
  },
};
</script>